import { default as about_45us8ROlxUhrWOMeta } from "C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/about-us.vue?macro=true";
import { default as contactW92hk1jFqnMeta } from "C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/contact.vue?macro=true";
import { default as endemics5uO0TNDcmPMeta } from "C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/endemics.vue?macro=true";
import { default as indexCejTXHzS2hMeta } from "C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/index.vue?macro=true";
import { default as non_45endemics1jJ4q4w443Meta } from "C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/non-endemics.vue?macro=true";
export default [
  {
    name: about_45us8ROlxUhrWOMeta?.name ?? "about-us___de",
    path: about_45us8ROlxUhrWOMeta?.path ?? "/de/about-us",
    meta: about_45us8ROlxUhrWOMeta || {},
    alias: about_45us8ROlxUhrWOMeta?.alias || [],
    redirect: about_45us8ROlxUhrWOMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us8ROlxUhrWOMeta?.name ?? "about-us___en",
    path: about_45us8ROlxUhrWOMeta?.path ?? "/en/about-us",
    meta: about_45us8ROlxUhrWOMeta || {},
    alias: about_45us8ROlxUhrWOMeta?.alias || [],
    redirect: about_45us8ROlxUhrWOMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: contactW92hk1jFqnMeta?.name ?? "contact___de",
    path: contactW92hk1jFqnMeta?.path ?? "/de/contact",
    meta: contactW92hk1jFqnMeta || {},
    alias: contactW92hk1jFqnMeta?.alias || [],
    redirect: contactW92hk1jFqnMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactW92hk1jFqnMeta?.name ?? "contact___en",
    path: contactW92hk1jFqnMeta?.path ?? "/en/contact",
    meta: contactW92hk1jFqnMeta || {},
    alias: contactW92hk1jFqnMeta?.alias || [],
    redirect: contactW92hk1jFqnMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: endemics5uO0TNDcmPMeta?.name ?? "endemics___de",
    path: endemics5uO0TNDcmPMeta?.path ?? "/de/endemics",
    meta: endemics5uO0TNDcmPMeta || {},
    alias: endemics5uO0TNDcmPMeta?.alias || [],
    redirect: endemics5uO0TNDcmPMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/endemics.vue").then(m => m.default || m)
  },
  {
    name: endemics5uO0TNDcmPMeta?.name ?? "endemics___en",
    path: endemics5uO0TNDcmPMeta?.path ?? "/en/endemics",
    meta: endemics5uO0TNDcmPMeta || {},
    alias: endemics5uO0TNDcmPMeta?.alias || [],
    redirect: endemics5uO0TNDcmPMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/endemics.vue").then(m => m.default || m)
  },
  {
    name: indexCejTXHzS2hMeta?.name ?? "index___de",
    path: indexCejTXHzS2hMeta?.path ?? "/de",
    meta: indexCejTXHzS2hMeta || {},
    alias: indexCejTXHzS2hMeta?.alias || [],
    redirect: indexCejTXHzS2hMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexCejTXHzS2hMeta?.name ?? "index___en",
    path: indexCejTXHzS2hMeta?.path ?? "/en",
    meta: indexCejTXHzS2hMeta || {},
    alias: indexCejTXHzS2hMeta?.alias || [],
    redirect: indexCejTXHzS2hMeta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/index.vue").then(m => m.default || m)
  },
  {
    name: non_45endemics1jJ4q4w443Meta?.name ?? "non-endemics___de",
    path: non_45endemics1jJ4q4w443Meta?.path ?? "/de/non-endemics",
    meta: non_45endemics1jJ4q4w443Meta || {},
    alias: non_45endemics1jJ4q4w443Meta?.alias || [],
    redirect: non_45endemics1jJ4q4w443Meta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/non-endemics.vue").then(m => m.default || m)
  },
  {
    name: non_45endemics1jJ4q4w443Meta?.name ?? "non-endemics___en",
    path: non_45endemics1jJ4q4w443Meta?.path ?? "/en/non-endemics",
    meta: non_45endemics1jJ4q4w443Meta || {},
    alias: non_45endemics1jJ4q4w443Meta?.alias || [],
    redirect: non_45endemics1jJ4q4w443Meta?.redirect,
    component: () => import("C:/Users/Vedat Cepni/WS Projekte/WS intern/BackseatGeeks/Development/backseatgeeks-remastered/pages/non-endemics.vue").then(m => m.default || m)
  }
]